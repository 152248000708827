/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { capitalize } from '../utils/string';
import { CheckIcon } from '../components/Icon';

export default ({ servicePoint: s, isSelected, onSelect, ...props }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const translateAgent = s => s && s.replace('MH-asiamies', translate('buyParcel.pickup.agent'));
  const hasAddress = !!(s.officeStreetAddress || s.officePostalCode || s.officeCity);
  return (
    <Box
      {...props}
      sx={{
        py: 12,
        px: 3,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        bg: isSelected ? 'primary' : 'white',
        color: isSelected ? 'white' : 'text',
        cursor: 'pointer',
        lineHeight: 'heading',
        ':first-of-type': {
          border: 0,
        },
        '& + div': {
          borderTopColor: isSelected ? 'transparent' : 'border',
        },
        ':hover': {
          bg: isSelected ? 'primary' : 'blueLighter',
        },
      }}
      onClick={onSelect}
    >
      <Box sx={{ fontWeight: 'medium' }}>{translateAgent(capitalize(s.officeName))}</Box>
      {hasAddress && (
        <Box>
          {capitalize(s.officeStreetAddress)}, {s.officePostalCode} {capitalize(s.officeCity)}
        </Box>
      )}
    </Box>
  );
};
