/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React from 'react';
import { useTranslate } from '../utils/getLanguage';
import { capitalize } from '../utils/string';
import { OpenTimes } from '../map/ServiceDetails';

export default ({ sp, departurePoint }) => {
  const translate = useTranslate();
  const { officeName, officeStreetAddress, officePostalCode, officeCity, parcelservices } = sp || {};
  const openTimes = parcelservices?.[0]?.openTimes || [];
  if (!sp || !sp.officeCode) return null;
  return (
    <Box sx={{ mb: 3 }}>
      <div sx={{ borderTop: '1px solid', borderColor: 'border' }} />
      <div sx={{ mt: 2, fontWeight: 'medium' }}>{capitalize(officeName)}</div>
      <div>
        {capitalize(officeStreetAddress)}, {officePostalCode} {capitalize(officeCity)}
      </div>
      {openTimes.length > 0 && (
        <>
          <div sx={{ mt: 2 }}>{translate('servicePoints.openTimes')}</div>
          <OpenTimes openTimes={openTimes} />
        </>
      )}
      <div sx={{ mt: 2 }}>
        {translate(departurePoint ? 'buyParcel.express.sendNote' : 'buyParcel.express.receiveNote')}
      </div>
    </Box>
  );
};
